import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
const useStyles = makeStyles(theme => ({
  padding24: {
    padding: 24,
  },
  marginTop24: {
    marginTop: 24,
  },
  marginTop8: {
    marginTop: 8,
  },
  link: {
    textDecoration: 'none',
  },
}))
const Forgot = () => {
  const classes = useStyles()
  const [snack, setSnack] = useState(false)
  const [msg, setMsg] = useState('')

  function closeSnack() {
    setSnack(false)
  }

  function handleForgot(event) {
    event.preventDefault()
    setMsg('Password reset instruction has been sent to your email')
    setSnack(true)
  }
  return (
    <Layout>
      <SEO title="Forgot Password" />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={closeSnack}
        message={msg}
      />
      <Container>
        <form
          noValidate
          onSubmit={handleForgot}
          style={{ marginTop: 170, marginBottom: 84 }}
        >
          <Grid container spacing={2} alignItems="center" justify="center">
            <Grid item md={4}>
              <Paper square>
                <div style={{ backgroundColor: '#F00', padding: 4 }}></div>
                <div className={classes.padding24}>
                  <Typography variant="h5" align="center">
                    Password Recovery
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.marginTop24}
                    align="center"
                  >
                    Please enter your registed email
                  </Typography>
                  <TextField
                    className={classes.marginTop24}
                    fullWidth
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                  />
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.marginTop24}
                  >
                    Send Recovery Instruction
                  </Button>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Layout>
  )
}

export default Forgot
